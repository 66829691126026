@import url('https://rsms.me/inter/inter.css');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

* {  
  -webkit-backface-visibility:  hidden;
  -webkit-tap-highlight-color:  transparent;
}


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}


::-webkit-scrollbar-track {
	border: 1px solid #333;
	background-color: #F5F5F5;
}

::-webkit-scrollbar {
	width: 14px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
	background-color: #000000;	
}

body {
  overscroll-behavior: none;
  background: #efefef;
  font-family: 'Inter var', sans-serif;
  /*
  cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iYmxhY2siLz48L3N2Zz4='),
    auto;
  */
}

.ui-footer {
  position: fixed;
  bottom: 0; left: 0; width: 230px;
  height: 100px;
  background-color: #efefef;
}

.ui-logotype {
  width: 230px;
  position: absolute;
  top: 40px;
}

h1 {
  font-size: 1.3em;
  line-height: 1.3em;
  font-weight: 300;
  letter-spacing: -0.05em;
  margin: 0;
  padding: 0;
  will-change: transform;

  position: absolute;
  top: 170px;
  left: 40px;
  width: 280px;

  color: #000;
  letter-spacing: 1px;

  /*
  color: #000;
  background-color: #fff;
  padding: 20px;
  */
}

h1 span {
  display: block;
  font-size: 0.9em;
  margin-top: 30px;
  font-weight: bold;
  letter-spacing: normal;
}

h1 i {
  display: block;
  margin-top: 44px;
  font-size: 0.7em;
  letter-spacing: normal;
}

.ui-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 1em;
  line-height: 28px;
}

.ui-info a {
  display: block;
  padding: 10px 16px; 
  background-color: #F5F5F5;
  transition: all 0.2s ease;
  margin-bottom: 0;
}

.ui-info i {
  font-size: 1.2em;
}

.ui-info b {
  font-weight: bold;
}

.ui-info a:hover {
  background-color: #000;
  color: #fff;
}


.ui-h2-footer {
  font-size: 40px;
  line-height: 44px;
  padding: 30px;
  width: 300px;
}


@media (min-width: 600px) {

  .ui-footer {
    height: 200px;
    width: 500px;
    background-color: transparent;
  }

  .ui-logotype {
    width: 330px;
    margin-left: 20px;
  }

  h1 {
    font-size: 18px;
    line-height: 30px;
    color: #000;
    width: 400px;
    top: 200px;
    padding: 60px 50px;
  }

  h1 span {
    font-weight: normal;
    font-size: 18px;
  }

  h1 i {
    font-size: 0.7em;
  }

  .ui-info {
    bottom: 20px;
    left: 20px;
  }

  .ui-info a {
    margin-bottom: 3px;
  }


  .ui-h2-footer {
    font-size: 60px;
    line-height: 70px;
    padding: 30px;
    width: 600px;
    margin-top: 260px;
    margin-left: 160px;
  }

}

@media (min-width: 1500px) {

  .ui-logotype {
    width: 400px;
    margin-top: 50px;
  }

  h1 {
    font-size: 20px;
    line-height: 26px;
    width: 400px;
    top: 300px;
  }

  h1 span {
    font-size: 20px;
  }

  .ui-info {
    bottom: 30px;
    left: 30px;
  }

  .ui-info a {
      background-color: #ccc;
  }  

}


h2 {
  font-size: 20em;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
  will-change: transform;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}


.ui-whatsapp {
  position: absolute;
  bottom: 30px;
  right: 20px;
  background-color: #fff;
  font-size: 1.3em;
  display: block;
  padding: 10px 12px;
}